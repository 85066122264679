<template>
  <div class="div-main-list-page-table">
    <el-table ref="jobTable" v-loading="isLoading" :data="jobTableData" height="100%" :border="true"
      :header-cell-style="HEADER_CELL_STYLE" :cell-style="CELL_STYLE" @selection-change="handleJobSelectionChange">
      <!-- <el-table-column label="Job No" prop="id" width="160"></el-table-column> -->
      <el-table-column label="Reference No" width="150">
        <template v-slot="scope">
          <span>{{ formatReferenceNo(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Job Type" prop="typeDisplay" width="120" />
      <el-table-column label="Result" prop="mpaTransactions">
        <template v-slot="scope">
          <div style="display: flex; flex-direction: column; gap: 5px">
            <div :key="t.id" v-for="t in scope.row.mpaTransactions.slice().reverse()">
              <div v-if="t.isSuccessful === null">
                <el-tag type="warning">{{ t.sgTradexPushRequest ? `PENDING_ON_${t.updatedAt} (SgTradex)` : `PENDING_ON_${t.updatedAt}` }}</el-tag>
                <span style="padding-left: 4px;">-</span>
              </div>
              <div v-else-if="t.isSuccessful == true">
                <el-tag type="success">{{ t.sgTradexPushRequest ? `SUCCESS_ON_${t.updatedAt} (SgTradex)` : `SUCCESS_ON_${t.updatedAt}` }}</el-tag>
                <span style="padding-left: 4px;">{{ t.sgTradexPushRequest ? '' : t.mpaResponseMessageJson }}</span>
              </div>
              <div v-else>
                <el-tag type="error">{{ t.sgTradexPushRequest ? `FAILED_ON_${t.updatedAt} (SgTradex)` : `FAILED_ON_${t.updatedAt}` }}</el-tag>
                <span style="padding-left: 4px;">{{ t.sgTradexPushRequest && !t.sgTradexPushRequest.isSuccessful ? (t.sgTradexPushRequest.sgTradexResponseMessageJson ? t.sgTradexPushRequest.sgTradexResponseMessageJson : t.sgTradexPushRequest.errorMessage) : 
                (t.mpaResponseMessageJson ? t.mpaResponseMessageJson : t.errorMessage) }}</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Barge" width="150">
        <template v-slot="scope">
          <span>{{ scope.row.bargeName || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Vessel/Terminal" width="150">
        <template v-slot="scope">
          <span v-if="scope.row.type === 2">{{ scope.row.exWharfType === 0 ? (scope.row.locationLevel1 || '-') : (scope.row.floaterName || scope.row.locationLevel1 || '-') }}</span>
          <span v-else>{{ scope.row.vesselName || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Vessel Imo" width="100">
        <template v-slot="scope">
          <span>{{ scope.row.vesselImo || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="datetime" label="Location/Berth"
        width="250">
        <template v-slot="scope">
          <span> {{ scope.row.locationLevel1 && scope.row.locationLevel2 ? `${scope.row.locationLevel1} / ${scope.row.locationLevel2}` : (scope.row.locationLevel1 || scope.row.locationLevel2 || '-') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Action" width="100">
        <template v-slot="scope">
            <el-button size="mini" v-if="scope.row.mpaTransactions.length === 0 || scope.row.mpaTransactions.filter(t => t.isSuccessful === null || t.isSuccessful).length === 0" type="primary" round plain @click="handlePushToMpa(scope.row.id)">Resend</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="div-main-list-page-table-pagination" v-if="hasPagination && !isLoading">
      <el-pagination background @size-change="handleJobPageSizeChange" @current-change="handleJobPageChange"
        :page-size.sync="jobPagination.pageSize" :page-sizes="[5, 10, 20, 50, 100]"
        :current-page="jobPagination.pageNumber" layout="total, sizes, prev, pager, next, jumper"
        :total="jobPagination.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getStatusTagStyle, formatUtcTimeString } from '@/utils/index.js'
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
import DropdownActionMenu from '@/components/DropdownActionMenu'

export default {
  name: 'TableJob',
  props: {
    isLoading: Boolean,
    jobTableData: Array,
    jobPagination: Object,
    hasPagination: Boolean,
    jobsSelected: Array,
    isViewOnly: Boolean,
    activeTab: Number,
    availableBargesLoading: Boolean,
    availableBargesOptions: Array,
    hasTableMenu: Boolean
  },
  components: { DropdownActionMenu },
  data() {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      cascaderKey: 0,
    }
  },
  computed: {
    ...mapState({
      currentCompany: 'currentCompany',
    })
  },
  methods: {
    formatUtcTimeString,
    getStatusTagStyle,

    handleJobSelectionChange(val) {
      this.$emit('handleJobSelectionChange', val)
    },

    handleJobPageSizeChange(pageSize) {
      this.$emit('handleJobPageSizeChange', pageSize)
    },

    handleJobPageChange(pageNumber) {
      this.$emit('handleJobPageChange', pageNumber)
    },

    handlePushToMpa (jobId) {
      this.$emit('handlePushToMpa', jobId)
    },
    formatReferenceNo (item) {
      if (item.type === 2) return item.loading ? item.loading.cqNumber : '-'
      else return item.delivery ? item.delivery.bdnNumber : '-'
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dropdown-item:hover {
  background-color: #E7EBF0;
  color: #3f5f88;
}
</style>

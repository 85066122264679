var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "div-main-list-page-table" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.isLoading,
              expression: "isLoading",
            },
          ],
          ref: "jobTable",
          attrs: {
            data: _vm.jobTableData,
            height: "100%",
            border: true,
            "header-cell-style": _vm.HEADER_CELL_STYLE,
            "cell-style": _vm.CELL_STYLE,
          },
          on: { "selection-change": _vm.handleJobSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Reference No", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatReferenceNo(scope.row))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Job Type", prop: "typeDisplay", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "Result", prop: "mpaTransactions" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      _vm._l(
                        scope.row.mpaTransactions.slice().reverse(),
                        function (t) {
                          return _c("div", { key: t.id }, [
                            t.isSuccessful === null
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            t.sgTradexPushRequest
                                              ? `PENDING_ON_${t.updatedAt} (SgTradex)`
                                              : `PENDING_ON_${t.updatedAt}`
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-left": "4px" },
                                      },
                                      [_vm._v("-")]
                                    ),
                                  ],
                                  1
                                )
                              : t.isSuccessful == true
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            t.sgTradexPushRequest
                                              ? `SUCCESS_ON_${t.updatedAt} (SgTradex)`
                                              : `SUCCESS_ON_${t.updatedAt}`
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-left": "4px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            t.sgTradexPushRequest
                                              ? ""
                                              : t.mpaResponseMessageJson
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("el-tag", { attrs: { type: "error" } }, [
                                      _vm._v(
                                        _vm._s(
                                          t.sgTradexPushRequest
                                            ? `FAILED_ON_${t.updatedAt} (SgTradex)`
                                            : `FAILED_ON_${t.updatedAt}`
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-left": "4px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            t.sgTradexPushRequest &&
                                              !t.sgTradexPushRequest
                                                .isSuccessful
                                              ? t.sgTradexPushRequest
                                                  .sgTradexResponseMessageJson
                                                ? t.sgTradexPushRequest
                                                    .sgTradexResponseMessageJson
                                                : t.sgTradexPushRequest
                                                    .errorMessage
                                              : t.mpaResponseMessageJson
                                              ? t.mpaResponseMessageJson
                                              : t.errorMessage
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ])
                        }
                      ),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Barge", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.bargeName || "-"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Vessel/Terminal", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type === 2
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.exWharfType === 0
                                ? scope.row.locationLevel1 || "-"
                                : scope.row.floaterName ||
                                    scope.row.locationLevel1 ||
                                    "-"
                            )
                          ),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(scope.row.vesselName || "-")),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Vessel Imo", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.vesselImo || "-"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "datetime", label: "Location/Berth", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.locationLevel1 && scope.row.locationLevel2
                              ? `${scope.row.locationLevel1} / ${scope.row.locationLevel2}`
                              : scope.row.locationLevel1 ||
                                  scope.row.locationLevel2 ||
                                  "-"
                          )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Action", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.mpaTransactions.length === 0 ||
                    scope.row.mpaTransactions.filter(
                      (t) => t.isSuccessful === null || t.isSuccessful
                    ).length === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              round: "",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handlePushToMpa(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("Resend")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.hasPagination && !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "div-main-list-page-table-pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.jobPagination.pageSize,
                  "page-sizes": [5, 10, 20, 50, 100],
                  "current-page": _vm.jobPagination.pageNumber,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.jobPagination.total,
                },
                on: {
                  "size-change": _vm.handleJobPageSizeChange,
                  "current-change": _vm.handleJobPageChange,
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.jobPagination, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.jobPagination, "pageSize", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }